
































import AutoReplyActionsContainer from './AutoReplyActionsContainer.vue'
import PrivateMessagesSettingsContainer from '@/views/board-settings/private-messages-settings-container.vue'
import PmCommandsContainer from './PmCommandsContainer.vue'
import FreeLicenseActivationAlert from '@/components/FreeLicenseActivationAlert.vue'
import { InputSetups } from "@/mixins/input-setups";

import PiTabs from 'piramis-base-components/src/components/PiTabs.vue'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'
import FeedbackSettings from "piramis-base-components/src/shared/modules/BotFeedback/FeedbackSettings.vue";
import DrawerWidthMixin from 'piramis-base-components/src/logic/helpers/DrawerWidthMixin';

import { Component, Mixins } from 'vue-property-decorator'

enum BotFeedbackTab {
  BotFeedback = 'botFeedback',
  Settings = 'settings',
  AutoReply = 'autoReply',
  Command = 'command',
}

@Component({
  components: {
    FreeLicenseActivationAlert,
    FeedbackSettings,
    PrivateMessagesSettingsContainer,
    PmCommandsContainer,
    AutoReplyActionsContainer,
    PiTabs,
    CenteredColumnLayout
  },
  data() {
    return {
      BotFeedbackTab,
    }
  }
})
export default class BotFeedback extends Mixins(DrawerWidthMixin, InputSetups) {
  activeTab: BotFeedbackTab | null = null

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('bot_feedback_chat_bot_settings').toString(),
        value: BotFeedbackTab.Settings,
        icon: {
          name: 'mark_unread_chat_alt'
        }
      },
      {
        label: this.$t('menu_auto_reply_actions_title').toString(),
        value: BotFeedbackTab.AutoReply,
        icon: {
          name: 'reply'
        }
      },
      {
        label: this.$t('menu_pm_commands_title').toString(),
        value: BotFeedbackTab.Command,
        icon: {
          name: 'keyboard_command_key'
        }
      },
      {
        label: this.$t('bot_feedback_main_settings').toString(),
        value: BotFeedbackTab.BotFeedback,
        icon: {
          name: 'settings'
        }
      },
    ]
  }

  changeTab(tab: BotFeedbackTab): void {
    if (this.activeTab !== tab) {
      this.activeTab = tab

      this.$router.replace({
        name: 'Bot_feedback',
        query: {
          tab
        }
      })
    }
  }

  initTab() {
    const tabQuery = this.$route.query?.tab?.toString() as BotFeedbackTab | undefined

    if (tabQuery && Object.values(BotFeedbackTab).includes(tabQuery)) {
      this.activeTab = tabQuery;
    } else {
      this.changeTab(BotFeedbackTab.Settings)
    }
  }

  saveConfig(config: any) {
    this.$store.commit("set_private_message_settings", config)

    this.$store.dispatch('savePostBoardConfig')
  }

  mounted():void {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        if (this.$store.state.boardsState.activeBoard) {
          if (this.$store.state.boardsState.activeBoard.config.pm_config.initial_message === null) {
            this.$store.state.boardsState.activeBoard.config.pm_config.initial_message = []
          }

          if (this.$store.state.boardsState.activeBoard.config.pm_config.user_auto_reply_actions === null) {
            this.$store.state.boardsState.activeBoard.config.pm_config.user_auto_reply_actions = []
          }

          if (this.$store.state.boardsState.activeBoard.config.pm_config.commands === null) {
            this.$store.state.boardsState.activeBoard.config.pm_config.commands = []
          }
        }
      }
    })

    this.initTab()
  }
}
